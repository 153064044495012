/* eslint no-undef: 0 */
// import 'babel-polyfill';

// Load Styles
import '../scss/style.scss';

// Bootstrap dependencies
// import '@popperjs/core';
// import 'bootstrap';


// FontAwesome
// import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import '@fortawesome/fontawesome-free/js/fontawesome';

// Vendor
// import 'magnific-popup/dist/jquery.magnific-popup.min'; // Need to install via NPM

// Custom Scripts
// import './gsap';
// import './TweenMax';
import './froogaloop';
import './scripts';
import './animations';


// import './main-nav-controller';